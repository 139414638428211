import React, {useState, useEffect} from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import Aos from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import '../style/quotes.css'
import EssayWithFootnotes from './EssayWithFootnotes';

const ModalComponent = (props) => {
  const {modalBody} = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    Aos.init({
        duration: 1200
    })
}, []);

  const [currentPage, setCurrentPage] = useState(0);
  
  const pages = modalBody.text.split('|');
  
  const goToPage = (page) => {
    if (page >= 0 && page < pages.length) {
      setCurrentPage(page);
    }
  };

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };


  const CloseIcon = styled.a`
  position: absolute;
  right: -5%;
  top: -40px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }

  &:before, &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: red;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

  

  return (
    <Modal
      closeIcon={<CloseIcon/>}
      open={open}
      trigger={<Button style={{position: 'absolute', height:'200px', width:'200px', background: 'transparent', border: 'none !important', fontSize:'0'}}></Button>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      data-aos="zoom-in"
      style={{background: '#f1f1e5', maxHeight:'80%'}}
    >
      <Header size='huge' style={{borderBottom: 'none', background: '#f1f1e5' }} content={modalBody.title || modalBody.question}/>
      {modalBody.subtitle && <Header size='medium' style={{background: '#f1f1e5', fontStyle: 'italic'}}> {modalBody.subtitle}</Header>}
      <Modal.Content scrolling style={{borderTop: '1px solid rgba(34,36,38,.15)', background: '#f1f1e5' }}>
      <EssayWithFootnotes text={pages[currentPage]} />     
    
      </Modal.Content>

      {modalBody.footnotes &&
          <Modal.Content scrolling style={{borderTop: '1px solid rgba(34,36,38,.15)', background: '#f1f1e5' }}>
            <p style={{fontSize:'12px'}}>
              {modalBody.footnotes.split('|').map((footnote, index) => (
                <span key={index}>
                  {index + 1}. {footnote}<br />
                </span>
              ))}
            </p>
          </Modal.Content>
        }
        
        <Modal.Actions style={{background: '#f1f1e5'}}>
        {pages.length > 1 &&
          <ul className='pagination'>
            {currentPage !== 0 ?
            <li onClick={prevPage}>
              <p className='first'>Previous</p>
            </li>
            :
            <li style={{opacity: '0.6', pointerEvents: 'none'}}>
              <p className='first'>Previous</p>
            </li>
              }
            {[...Array(pages.length).keys()].map((page) => (
              <li key={page} onClick={() => goToPage(page)}>
                <p>{page + 1}</p>
              </li>
            ))}
            {currentPage !== pages.length -1 ?
            <li onClick={nextPage}>
              <p className='next'>Next</p>
            </li>
            :
            <li  style={{opacity: '0.6', pointerEvents: 'none'}}>
              <p className='next'>Next</p>
            </li>
            }
          </ul>
            }
          </Modal.Actions>
        
      
    </Modal>
  )
}

export default ModalComponent;