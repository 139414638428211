import React from "react";
import '../style/nav.css'
import {Grid, Header} from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom';

const Nav = () => {

    let opacityChange = false;
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    async function menuOnClick(e) {
        e.preventDefault(); // Prevent the default behavior of the link
        
        document.getElementById("menu-bar").classList.toggle("change");
        document.getElementById("nav").classList.toggle("change");
        document.getElementById("menu-bg").classList.toggle("change-bg");
    
        if(document.getElementById("author-details")) {
            document.getElementById("author-details").style.opacity = opacityChange === false ? '30%' : '100%';
            document.getElementById("author-header").style.opacity = opacityChange === false ? '30%' : '100%';
        }
    
        opacityChange = !opacityChange;
    
        await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 500 milliseconds (adjust as needed)
    
        // if (e.target.href) {
        //     window.location.href = e.target.href; // Navigate to the link
        // }
    }

    return (
        <Grid divided='vertically'>
            <Grid.Row columns={isHomePage ? 2 : 1}>
                <Grid.Column width={1}>
                    <div id="navBar">
                        <div id="menu">
                            <div id="menu-bar" onClick={menuOnClick}>
                                <div id="bar1" className="bar"></div>
                                <div id="bar2" className="bar"></div>
                                <div id="bar3" className="bar"></div>
                            </div>
                            <nav className="nav" id="nav" onClick={menuOnClick}>
                                <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="menu-bg" id="menu-bg"></div>
                    </div>
                    </Grid.Column>
                    
                    <Grid.Column>
                        {isHomePage &&
                        <Header id="author-header" style={{color: "#1F3252", margin: "20px 0 0px 40px", whiteSpace: "nowrap"}} as='h1' textAlign='left'>Being Human: Scattered Thoughts of a Psychoanalytic Writer</Header>
                    }
                    {!isHomePage &&
                        <Header id="author-header" style={{color: "#1F3252", margin: "20px 0 0px 40px", whiteSpace: "nowrap"}} as='h1' textAlign='left'></Header>
                    }
                        </Grid.Column>
                        
            </Grid.Row>
        </Grid>
    )
}

export default Nav;