import React, { useState, useEffect, useContext } from 'react';
import PageFooter from '../components/PageFooter';
import Quotes from '../components/Quotes';
import EditQuotes from '../components/EditQuotes';
import EditEssays from '../components/EditEssays';
import Login from '../components/Login';
import {Grid, Header, Divider, Image, GridColumn, Container, Form, Button, Select, ButtonGroup} from 'semantic-ui-react'
import {collection, getDocs} from 'firebase/firestore';
import { db } from '../lib/init-firebase';
import { useAuth } from '../services/AuthContext';

const Admin = () => {
  const { authUser, userSignOut } = useAuth();
    const [quoteToUpdate, setQuoteToUpdate] = useState(null);
    const [updateQuote, setUpdateQuote] = useState(null)
    const [add, addQuote] = useState(null)

    const [essayToUpdate, setEssayToUpdate] = useState(null);
    const [updateEssay, setUpdateEssay] = useState(null)
    const [essayAdd, addEssay] = useState(null)


    const [quotes, setQuotes] = useState([]);
    const [essays, setEssays] = useState([]);
    
    useEffect(() => {
      getQuotes()
      getEssays()
    }, []);
    
  
    async function getQuotes() {
      const quoteCollection = collection(db, "quotes")
      getDocs(quoteCollection).then(response => {
        const quotes = response.docs.map(doc => ({
          data: doc.data(),
          id: doc.id
        }))
        setQuotes(quotes)
      }).catch(error => console.log(error.message))
    }
  
    async function getEssays() {
      const quoteCollection = collection(db, "essays")
      getDocs(quoteCollection).then(response => {
        const essays = response.docs.map(doc => ({
          data: doc.data(),
          id: doc.id
        }))
        setEssays(essays)
      }).catch(error => console.log(error.message))
    }
  

    //QUOTES
    const handleUpdateQuoteSuccess = () => {
        setQuoteToUpdate(null)
        addQuote(false)
        getQuotes();
    };

    const handleAddQuoteClick = () => {
        setUpdateQuote(false)
        addQuote(true)
        setQuoteToUpdate({ data: { text: '' }});
    };

    const handleSelectQuoteChange = (e, { value }) => {
        setUpdateQuote(true)
        let selectedOption = quotes.find(option => option.id === value);
        setQuoteToUpdate(selectedOption);
      };

      const handleCancelQuoteClick = () => {
        document.getElementById('quoteForm').selectedIndex = -1;
        setUpdateQuote(false)
        addQuote(null)
        setQuoteToUpdate('');
      }

          //Essays
    const handleUpdateEssaySuccess = () => {
        setEssayToUpdate(null);
        addEssay(false);
        getEssays();
      };
  
      const handleAddEssayClick = () => {
          setUpdateEssay(false)
          addEssay(true)
          setEssayToUpdate({ data: { text: '' }});
      };
  
      const handleSelectEssayChange = (e, { value }) => {
          setUpdateEssay(true)
          let selectedOption = essays.find(option => option.id === value);
          setEssayToUpdate(selectedOption);
        };

    const handleCancelEssayClick = () => {
        setUpdateEssay(false)
        addEssay(null)
        setEssayToUpdate(null);
        }
    
    return (
        <Container>
          {authUser ? (
          <>
          <h1>Welcome, {authUser.email}!</h1>
            <Container>
            <h1>Quotes</h1>
            <Form.Group inline style={{padding: "10px"}}>
            <Form.Field id='quoteForm'
                            control={Select}
                            options={quotes.map(quote => ({ key: quote.id, text: quote.data.question || quote.data.text, value: quote.id }))}
                            placeholder='Select Quote'
                            onChange={handleSelectQuoteChange}

                        />
                    </Form.Group>
                {quoteToUpdate && (
                            <EditQuotes
                            quote={quoteToUpdate}
                            isUpdate={updateQuote}
                            onUpdateSuccess={handleUpdateQuoteSuccess}
                            />
                            )}
                
                <Button.Group>
                    {! add && <Form.Field style={{margin:"10px"}} control={Button} onClick={handleAddQuoteClick}>Add Quote</Form.Field>}
                { quoteToUpdate && <Form.Field style={{margin:"10px"}} control={Button} onClick={handleCancelQuoteClick}>Cancel</Form.Field>}
                </Button.Group>
                
            </Container>
            <Container>
                <Divider style={{margin: "100px"}}/>
            <h1>Essays</h1>
            <p>Essay pages can be separated simply by entering a '|' symbol. To add footnotes, use ^1, ^2 within the text where ever you want the exponent to be. Then, add them to the footnotes section, using | to split these as well. </p>
            <Form.Group inline style={{padding: "10px"}}>
            <Form.Field id='essayForm'
                            control={Select}
                            options={essays.map(essay => ({ key: essay.id, text: essay.data.title || essay.data.text, value: essay.id }))}
                            placeholder='Select Essay'
                            onChange={handleSelectEssayChange}

                        />
                    </Form.Group>
                {essayToUpdate && (
                            <EditEssays
                            essay={essayToUpdate}
                            isUpdate={updateEssay}
                            onUpdateSuccess={handleUpdateEssaySuccess}
                            />
                            )}
                            <Button.Group>
                {! essayAdd && <Form.Field style={{margin:"10px"}} control={Button} onClick={handleAddEssayClick}>Add Essay</Form.Field>}
                { essayToUpdate && <Form.Field style={{margin:"10px"}} control={Button} onClick={handleCancelEssayClick}>Cancel</Form.Field>}\
                </Button.Group>
                
            </Container>
            <Button style={{margin: '50px'}} onClick={userSignOut}>Sign Out</Button>
            </>
          ) : (
            <Login></Login>
          )}
        </Container>
    )
}

export default Admin;