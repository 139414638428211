import React from 'react';
import {Button} from 'semantic-ui-react'
import { db } from '../lib/init-firebase';
import {collection, doc, setDoc} from 'firebase/firestore';

const SendEmail = ({onSend}) => {

    const handleButtonClick = async () => {
        let subject = document.getElementById('form-input-subject').value;
        let email = document.getElementById('form-input-email').value;
        let inquiry = document.getElementById('form-input-inquiry').value;
        if (!subject || !email ) {
            alert('Please fill out all required fields');
            return;
          }
        const mailRef = doc(collection(db, "mail"))
        const mailObject = {
            "to" : ["rishabnathan93@gmail.com"],
            "message" : {
                "html" : `${inquiry}`,
                "text" : `${inquiry}`,
                "subject": `${subject} - Message From: ${email}`
            }
        }
        try {
          await setDoc(mailRef, mailObject);
          onSend();
        } catch (error) {
          console.error('Error adding mail: ', error);
          return null;
        }
      };
  return (
    <div>
        <Button onClick={handleButtonClick} style={{color: 'white', background: "#303e4d"}}>Submit</Button>
    </div>
  );
};

export default SendEmail;