import React, { useEffect, useState, useRef } from 'react'
import {Container, Grid, Modal, Button, Image, Header} from 'semantic-ui-react'
import '../style/quotes.css'
import Aos from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import ModalComponent from './ModalComponent';
import { motion, useScroll, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer";

const Quotes = ({quotes, essays}) => {

    const [open, setOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [currentQuote, setCurrentQuote] = useState(" ");
    const [currentEssay, setCurrentEssay] = useState(" ");


    // const ref = useRef(null);
    // const { scrollYProgress } = useScroll({
    //   target: ref,
    //   offset: ["end end", "start start"]
    // });

    const scrollRef = useRef(null)

    function toggleModal() {
        setIsOpen(!isOpen);
      }
    
    function handleClick(quote, essay) {
        setIsOpen(!isOpen);
        setOpen(true)
        quote? setCurrentQuote(quote) : setCurrentEssay(essay)
    }


    const DiamondText = styled.div`
        color: white;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        transform: rotate(-45deg);
?        `;


    const CircleText = styled.div`
    color: white;
    font-size: 16px;
    `;

    // useEffect(() => {
    //     Aos.init({
    //         duration: 1200
    //     })
    // }, []);

    let displayedText = 0;

    function diamondSize(quote) {
        let screenWidth = window.innerWidth;
        let size = Math.min(300, screenWidth / 4.5)
        displayedText = quote.substring(0, size / 2)
        if (screenWidth < 1000) {
            return 280;
        }
        return 350;
    }

    function circleSize(quote) {
        let screenWidth = window.innerWidth;
        let size = Math.min(350, screenWidth / 3)
        displayedText = quote.substring(0, size / 2)
        if (screenWidth < 1000) {
            return 330;
        }
        return 400;
    }

    const calculateGridSize = () => {
        let screenWidth = window.innerWidth;
        return screenWidth > 600 ? 3 : 1; // Use 3 columns for larger screens, 1 column for smaller screens
      };

    const headerStyle = {
        // marginLeft: '185px',
        // marginRight: Math.min(350, window.innerWidth / 3),
        // whiteSpace: 'nowrap' 
        
    }

    let diamond = diamondSize("Generic Text")
    let circle = circleSize("Generic Text")
    let diamondHeader = window.innerWidth < 1000 ? '5%' : '-5%';
    let circleHeader = window.innerWidth < 1000 ? '10%' : '0%';
    let circleMargin = window.innerWidth < 1000 ? '10px' : '50px';
    const ref = useRef(null)
    const isInView = useInView({
        margin: "100px 100px 0px 0px"
      })
    const [diamondSizeDynamic, setDiamondSizeDynamic] = useState(diamondSize(""));

      useEffect(() => {
        function handleResize() {
            setDiamondSizeDynamic(diamondSize(""));
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <Container style={{paddingTop: '5%', maxWidth: '80%'}}>
            {/* <Container style={{marginTop: '5%'}}> */}
            {/* <p style={{float: 'right', position: 'sticky'}}>Click to expand</p> */}
                
                
                <Grid divided='vertically' columns={2} >
                    {/* <Grid.Row columns={3}> */}
                    <Grid.Column >
                    <Header className='diamondHeader' >My Reflections</Header>
                    {essays.map((item, index) => (
                        <div>
                            <AnimatePresence>
                            <motion.li 
                            key={index}
                             initial={{ opacity: 0, height: diamondSizeDynamic-70, width:diamondSizeDynamic-70, transform: 'rotate(45deg)' }}
                             whileInView={{ opacity: 1,  height: diamondSizeDynamic, width:diamondSizeDynamic,  transform: 'rotate(45deg)' }}
                             transition={{
                               ease: 'easeIn',
                               duration: 0.6
                             }}
                             className='diamond' size={diamondSize(item.data.title)} >
                            <DiamondText className='diamondText' onClick={() => handleClick(item)} style={{ textOverflow: 'ellipsis',opacity:1 }}>
                                {displayedText}
                            </DiamondText>
                            <ModalComponent modalBody={(item.data)}> </ModalComponent>
                            </motion.li>
                            </AnimatePresence>
                        </div>
                    
                    ))}
                    </Grid.Column>
                    <Grid.Column >
                    <Header className='circleHeader'>Anonymous Reflections</Header>
                    {quotes.map((item, index) => (
                        <div >
                        <AnimatePresence>
                        <motion.li
                            key={index} 
                             initial={{ opacity: 0, height: circle-70, width:circle-70 }}
                             whileInView={{ opacity: 1, height: circle, width:circle }}
                             ref={ref}
                             viewport={{circleMargin}}
                             transition={{ ease:'easeIn', duration:0.6 }} className='circle' style={{padding: '20px' }} diameter={circleSize(item.data.question)}>
                            <CircleText onClick={() => handleClick(item)} style={{ textOverflow: 'ellipsis',opacity:1 }}>
                                {displayedText}
                            </CircleText>
                            <ModalComponent modalBody={(item.data)}> </ModalComponent>
                            </motion.li>
                            </AnimatePresence>
                            </div>
                    ))}
                    </Grid.Column>
                
                    {/* </Grid.Row> */}
                    </Grid>
                
            
            
                
            {/* </Container> */}
        </Container>
    )
}

export default Quotes;

