import './App.css';
import React, {useState, useEffect, useMemo } from 'react';
import AboutAuthor from './containers/AboutAuthor';
import Nav from './components/Nav'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './containers/Home';
import Admin from './containers/Admin';
import Login from './components/Login';
import {collection, getDocs} from 'firebase/firestore';
import { db } from './lib/init-firebase';

function App() {

  const [quotes, setQuotes] = useState([]);
  const [essays, setEssays] = useState([]);
  // const [shuffledArray, setShuffledArray] = useState([]);
  // const [shuffedEssays, setShuffledEssays] = useState([])
  // const [shuffledQuotes, setShuffledQuotes] = useState([])


  useEffect(() => {
    async function getQuotes() {
      const quoteCollection = collection(db, "quotes");
      const snapshot = await getDocs(quoteCollection);
      const quotesData = snapshot.docs.map(doc => ({ data: doc.data(), id: doc.id }));
      setQuotes(quotesData);
    }

    async function getEssays() {
      const essayCollection = collection(db, "essays");
      const snapshot = await getDocs(essayCollection);
      const essaysData = snapshot.docs.map(doc => ({ data: doc.data(), id: doc.id }));
      setEssays(essaysData);
    }

    getQuotes();
    getEssays();
  }, []);


  const memoizedHomeComponent = useMemo(() => <Home quotes={quotes} essays={essays} />, [quotes, essays]);

  return (
    <div className="App">
      
      <Router>
        <Nav/>
        <Routes>
          <Route path="/" element={memoizedHomeComponent} />
          <Route path="/about" element={<AboutAuthor/>}/> 
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
