import React, { useState, useEffect } from 'react';
import { db } from '../lib/init-firebase';
import {collection, deleteDoc, doc, getDocs, setDoc, updateDoc} from 'firebase/firestore';
import {Form, TextArea, Button} from 'semantic-ui-react'

function EditEssays({essay, isUpdate, onUpdateSuccess }) {

  console.log(essay)
    const [text, setText] = useState(essay.data.text || '');
    const [title, setTitle] = useState(essay.data.title || '');
    const [subtitle, setSubtitle] = useState(essay.data.subtitle || '');
    const [footnotes, setFootnotes] = useState(essay.data.footnotes || '');
    const [del, setDel] = useState(null);

    useEffect(() => {
        setText(essay?.data.text || '');
        setTitle(essay?.data.title || '');
        setSubtitle(essay?.data.subtitle || '');
        setFootnotes(essay?.data.footnotes || '');
      }, [essay]);

      const handleButtonClickDelete = () => {
        setDel(true);
      }

      const handleButtonClickUpdate = () => {
        setDel(false);
      }  

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newEssay = text;
      const newTitle = title;
      const newSubtitle = subtitle;
      const newFootnotes = footnotes;
  
      if (isUpdate) {
        await updateEssay(essay.id, newEssay, newTitle, newSubtitle, newFootnotes);
      } if (del && essay.data.text !== '') {
        console.log(essay)
        await deleteEssay(essay.id)
      } else if (del && essay.data.text === '') {
        alert('Please add an essay')
        return;
      } else if (!isUpdate) {
        await addEssay(newEssay, newTitle, newSubtitle, newFootnotes);
      }
  
      if (onUpdateSuccess) {
        onUpdateSuccess();
      }
    };


    const addEssay = async (essay, essayTitle, essaySubtitle, essayFootnotes ) => {
        const essayAddRef = doc(collection(db, "essays"))

        try {
          await setDoc(essayAddRef, {"text": essay, "essay": true, "title": essayTitle, "subtitle": essaySubtitle, "footnotes": essayFootnotes});
          console.log('Essay added with ID: ', essayAddRef.id);
          return essayAddRef.id;
        } catch (error) {
          console.error('Error adding essay: ', error);
          return null;
        }
      };

    const deleteEssay = async (essayId) => {
        const essayDelRef =  doc(db, "essays", essayId)
        console.log("deleting", essayId)
        try {
            await deleteDoc(essayDelRef);
            console.log('Deleted essay with ID: ', essayDelRef.id);
        } catch (error) {
            console.error('Error deleting essay: ', error);
            return null;
        }
    };  
      
    const updateEssay = async (essayId, updatedEssay, updatedTitle, updatedSubtitle, updatedFootnotes) => {
        const essayRef = doc(db, "essays", essayId)
        console.log(essayRef)
        try {
            await updateDoc(essayRef, {"text": updatedEssay, "title": updatedTitle, "subtitle": updatedSubtitle, "footnotes": updatedFootnotes})
          return true;
        } catch (error) {
          console.error('Error updating essay: ', error);
          return false;
        }
      };
    
    return (
        <div>
             <Form onSubmit={handleSubmit}>
             <Form.Field
                    label="Essay Title:"
                    control={TextArea}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter your title..."
                    required
                />
                <Form.Field
                    label="Essay Subtitle:"
                    control={TextArea}
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                    placeholder="Enter your subtitle..."
                    
                />
                <Form.Field
                    label="Essay Text:"
                    control={TextArea}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter your text..."
                    required
                />
                 <Form.Field
                    label="Essay Foototes:"
                    control={TextArea}
                    value={footnotes}
                    onChange={(e) => setFootnotes(e.target.value)}
                    placeholder="Enter your footnotes..."
                    
                />
                <Form.Field control={Button} type="submit" onClick={handleButtonClickUpdate}>{isUpdate ? 'Update Essay' : 'Add Essay'}</Form.Field>
                {isUpdate && <Form.Field control={Button} type="submit" onClick={handleButtonClickDelete}> Delete</Form.Field> }
                </Form>
        </div>
    )
}

export default EditEssays;