import React from 'react';
import AuthorDetails from '../components/AuthorDetails'
import PageFooter from '../components/PageFooter';

function AboutAuthor() {
    return (
        <div id='author-page'>
        <div className='author-content'>
          <AuthorDetails />
        </div>
        <PageFooter className='page-footer' />
      </div>
    )
}

export default AboutAuthor;