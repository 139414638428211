import React, { useRef, useState } from "react"
import { UserAuth } from "../services/AuthContext"
import {Card, Form, Button, Popup} from 'semantic-ui-react'
import {auth} from '../lib/init-firebase'
import { signInWithEmailAndPassword } from "firebase/auth";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    const signIn = (e) => {
      e.preventDefault();
      setErrorMessage(null);

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.log(userCredential);
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage("Invalid email or password. Please try again.");
        });
    };
  
    return (
      <div className="sign-in-container">
        <Form onSubmit={signIn}>
          <h1>Log In:</h1>
          <Form.Field>
          <label style={{textAlign: 'left'}}>Email</label>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          </Form.Field>
          
          <Form.Field>
            <label style={{textAlign: 'left'}}>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          </Form.Field>
          <Button type="submit">Log In</Button>
          {errorMessage && (
          <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
        )}
        </Form>
      </div>
    );
  };