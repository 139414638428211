import React from 'react';

const EssayWithFootnotes = ({ text}) => {
  const formattedText = text.split(/(\^\d+)/).map((chunk, index) => {
    if (index % 2 === 1) {
        const footnoteNumber = chunk.replace(/^\^/, ''); 
        return <sup key={index}>{footnoteNumber}</sup>;
    }
    return chunk;
  });

  return (
    <div style={{ whiteSpace: 'pre-wrap', fontSize:'18px' }}>
      {formattedText}
    </div>
  );
};

export default EssayWithFootnotes;