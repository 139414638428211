import React, {useState, useEffect, useRef} from 'react'
import template from '../assets/Face Template.png'
import {Container, Divider, Grid, Button, Modal, Form, Header, TextArea} from 'semantic-ui-react'
import '../style/aboutAuthor.css'
import styled from 'styled-components';
import ModalComponent from './ModalComponent';
import Aos from "aos";
import "aos/dist/aos.css";
import SendEmail from './SendEmail';


const AuthorDetails = () => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        Aos.init({
            duration: 1200
        })
    }, []);

    

    // const [formData, setFormData] = useState({
    //     subject: '',
    //     email: '',
    //     inquiry: '',
    //   });
    
    //   const formDataRef = useRef(formData);
    
    //   useEffect(() => {
    //     formDataRef.current = formData;
    //   }, [formData]);
    
    //   const handleInputChange = (e, { name, value }) => {
    //     setFormData((prevFormData) => ({
    //       ...prevFormData,
    //       [name]: value,
    //     }));
    //   };
      const handleSendEmail = () => {
        setOpen(false);
      };

    const DiamondContainer = styled.div`
        width: ${props => `${props.size}px`};
        height: ${props => `${props.size}px`};
        background-color: #333;
        transform: rotate(45deg);
        position: relative;
        // overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px
        
        `;

    const DiamondText = styled.div`
        color: white;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        transform: rotate(-45deg);
?        `;

    const CircleContainer = styled.div`
    width: ${props => `${props.diameter}px`};
    height: ${props => `${props.diameter}px`};
    background-color: #333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    `;

    const CircleText = styled.div`
    color: white;
    font-size: 12px;
    `;

    let displayedText = 0;

    function diamondSize(text) {
        const screenWidth = window.innerWidth;
        let size = Math.min(300, screenWidth / 5)
        displayedText = text
        return size;
    }

    function circleSize(text) {
        const screenWidth = window.innerWidth;
        let size = Math.min(300, screenWidth / 5)
        displayedText = text
        return size;
    }

    return (
        <div>
            <Container>
                
                    <Container id='author-details'>
                        
                        <Divider /> 
                        <p style={{textAlign: 'justify', fontSize: '24px'}} className='general-text' >Rishab Nathan, M.A. is a Psychoanalyst-in-training and a PhD candidate in New York City. His work has spanned across film, politics, and academia. He is currently focused on bringing psychoanalysis, especially in its Freudian and Lacanian orientation, to the forefront of both therapeutic and political thought. You can spend your time reading his work here but you will be much better served laying on a psychoanalytic couch and undergoing psychoanalysis.  </p>
                        <Grid centered>
                            <DiamondContainer style={{ margin: '90px', padding: '20px' }} size={diamondSize(("Contact"))}>
                            <DiamondText style={{ textOverflow: 'ellipsis', fontSize: '14px' }}>
                                Contact Me
                            </DiamondText>
                            <Modal
                            className='contact'
                                closeIcon
                                open={open}
                                trigger={<Button style={{position: 'absolute', height:'200px', width:'200px', background: 'transparent', border: 'none !important', fontSize:'0'}}></Button>}
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                data-aos="zoom-in"
                                style={{background: '#f1f1e5', width: '80%'}}
                                size='fullscreen'
                                >
                                <Modal.Content>
                                <Header size='huge' style={{borderBottom: 'none' }} content="Contact Me"/>
                                <Form>
                                <Form.Input
                                    required={true}
                                    fluid
                                    label='Name'
                                    name='subject'
                                    placeholder='Enter your name...'
                                    id='form-input-subject'
                                   
                                    />
                                    <Form.Input
                                        required={true}
                                        fluid
                                        name='email'
                                        label='Your Email'
                                        id='form-input-email'
                                        placeholder='Enter your email...'

                                    />
                                    <TextArea
                                        required={true}
                                        fluid
                                        name='inquiry'
                                        id='form-input-inquiry'
                                        style={{ minHeight: 200 }}
                                        placeholder='Enter your inquiry...'
                                    />
                                    </Form>
                                    <Divider></Divider>
                                    <SendEmail onSend={handleSendEmail}></SendEmail>
                                </Modal.Content>
                                </Modal>
                            {/* <ModalComponent modalBody={{title: "Contact Form", text: "Contact Here" }}> </ModalComponent> */}

                            </DiamondContainer>

                        </Grid>
                        <img className='background-image' style={{width: "100%"}} src={template} alt="Author pic"/>`
                    </Container>
            
            </Container>
        </div>
    )
}

export default AuthorDetails;

