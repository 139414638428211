import React, { useEffect, useState, useMemo  }from 'react';
import PageFooter from '../components/PageFooter';
import Quotes from '../components/Quotes';

const Home = ({quotes, essays}) => {
  const [shuffledQuotes, setShuffledQuotes] = useState([]);
  const [shuffledEssays, setShuffledEssays] = useState([]);

  const memoizedQuotes = useMemo(() => [...quotes], [quotes]);
  const memoizedEssays = useMemo(() => [...essays], [essays]);
  
  useEffect(() => {
    let shuffledQuotes = [...memoizedQuotes];
    for (let i = shuffledQuotes.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledQuotes[i], shuffledQuotes[j]] = [shuffledQuotes[j], shuffledQuotes[i]];
    }
    let shuffledEssays = [...memoizedEssays];
    for (let i = shuffledEssays.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledEssays[i], shuffledEssays[j]] = [shuffledEssays[j], shuffledEssays[i]];
    }
    setShuffledQuotes(shuffledQuotes);
    setShuffledEssays(shuffledEssays);
  }, [memoizedQuotes, memoizedEssays]);


    return (
        <div>
            <Quotes quotes={shuffledQuotes} essays={shuffledEssays}/>
            <PageFooter />
        </div>
    )
}

export default Home;