import React from "react";
import {Header, Segment, Grid, List, Container, Icon} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../style/footer.css'
const PageFooter = () => {
    return (
        <Segment  className="page-footer" inverted vertical style={{ padding: '5em 0em', bottom: '0' }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={5}>
                <List link inverted>
                  <List.Item as={Link} to='/'>Home</List.Item>
                  <List.Item as={Link} to='/about'>About the Author</List.Item>
                  <List.Item as={Link} to='/about'>Contact Me</List.Item>
                </List>
              </Grid.Column>
            
              <Grid.Column width={5}>
                <Header as='h4' inverted>
                  Find Me On...
                </Header>
                <a href="https://www.linkedin.com/in/rishab-nathan-68b1811a3/" target="_blank" rel="noopener noreferrer">
                  <Icon link name='linkedin' size="big" />
                </a>
                <a href="https://www.instagram.com/rishab_nathan" target="_blank" rel="noopener noreferrer">
                  <Icon link name='instagram' size="big" />
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
}

export default PageFooter;