import React, { useState, useEffect } from 'react';

import { db } from '../lib/init-firebase';
import {collection, deleteDoc, doc, getDocs, setDoc, updateDoc} from 'firebase/firestore';
import {Form, TextArea, Button} from 'semantic-ui-react'

function EditQuotes({quote, isUpdate, onUpdateSuccess }) {

    const [text, setText] = useState(quote.data.text || '');
    const [title, setTitle] = useState(quote.data.question || '');
    const [del, setDel] = useState(null);

    useEffect(() => {
        setText(quote?.data.text || '');
        setTitle(quote?.data.question || '');
      }, [quote]);

      const handleButtonClickDelete = () => {
        setDel(true);
      }

      const handleButtonClickUpdate = () => {
        setDel(false);
      }  

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newQuote = text;
      const newTitle = title;
  
      if (isUpdate) {
        await updateQuote(quote.id, newQuote, newTitle);
      } if (del && quote.data.text !== '') {
        await deleteQuote(quote.id)
      } else if (del && quote.data.text === '') {
        alert('Please add a quote')
        return;
      } else if (!isUpdate) {
        await addQuote(newQuote, newTitle);
      }
  
      if (onUpdateSuccess) {
        onUpdateSuccess();
      }
    };

    const addQuote = async (quote, title) => {
        const quoteAddRef = doc(collection(db, "quotes"))

        try {
          await setDoc(quoteAddRef, {"text": quote, "question": title});
          console.log('Quote added with ID: ', quoteAddRef.id);
          return quoteAddRef.id;
        } catch (error) {
          console.error('Error adding quote: ', error);
          return null;
        }
      };

    const deleteQuote = async (quoteId) => {
        const quoteDelRef =  doc(db, "quotes", quoteId)

        try {
            await deleteDoc(quoteDelRef);
            console.log('Deleted quote with ID: ', quoteDelRef.id);
        } catch (error) {
            console.error('Error deleting quote: ', error);
            return null;
        }
    };  
      
    const updateQuote = async (quoteId, updatedQuote, updatedTitle) => {
        const quoteRef = doc(db, "quotes", quoteId)
        console.log(quoteRef)
        try {
            await updateDoc(quoteRef, {"text": updatedQuote, "question": updatedTitle})
          return true;
        } catch (error) {
          console.error('Error updating quote: ', error);
          return false;
        }
      };
    
    return (
        <div>
             <Form onSubmit={handleSubmit}>
               <Form.Field
                      label="Question:"
                      control={TextArea}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter your question/title..."
                      required
                  />
                <Form.Field
                    label="Answer:"
                    control={TextArea}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter your quote..."
                    required
                />
                <Form.Field control={Button} type="submit" onClick={handleButtonClickUpdate}>{isUpdate ? 'Update Quote' : 'Add Quote'}</Form.Field>
                {isUpdate && <Form.Field control={Button} type="submit" onClick={handleButtonClickDelete}> Delete</Form.Field>}
                </Form>
        </div>
    )
}

export default EditQuotes;